<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <b-row>
            <h4 class="ml-1 mb-2">Data Perawatan</h4>
            <b-col cols="12">
              <label>NOPOL Kendaraan:</label>
              <p v-if="model.vehicle">{{ model.vehicle.number }}</p>
              <label>KM Rencana:</label>
              <p>{{ model.plan_km }} KM</p>
              <label>Deskripsi:</label>
              <p>{{ model.description }}</p>
              <label>Tanggal Rencana:</label>
              <p>{{ tanggal(model.plan_date) }}</p>
              <!-- <label>Rencana Biaya:</label>
              <p>{{ 'Rp'+formatPrice(model.plan_cost) }}</p> -->
              <label>Tanggal Realisasi:</label>
              <p>{{ tanggal(model.realisation_date) }} </p>
              <label>Biaya Realisasi:</label>
              <p>{{ 'Rp'+formatPrice(model.realisation_cost) }} </p>
              <label>Km Realisasi:</label>
              <p>{{model.realisation_km}} KM</p>
              <label>Tanggal Masuk:</label>
              <p>{{ tanggal(model.created_at) }}</p>
              <!-- <label>Bengkel:</label>
              <p>{{ model.is_external ? 'Eksternal' : 'Internal'}}</p> -->
              <hr>
            </b-col>
            <b-col cols="12" v-if="!model.is_external">
              <h4>Data Bengkel</h4>
              <label>Nama:</label>
              <p>{{ model.workshop?model.workshop.name:'-' }}</p>
              <label>Address:</label>
              <p>{{ model.workshop?model.workshop.address:'-' }}</p>
            </b-col>
            <b-col cols="12" v-if="model">
              <hr>
              <label v-if="model.author != null">Petugas Pelaporan:</label>
              <p v-if="model.author != null">
                {{ model.author.name }}
              </p>
              <label>Status </label>
              <br />
              
              <span v-if="model.status" class="badge rounded-pill " :class="status[getStatus(model.status)]?'bg-'+status[getStatus(model.status)].color:'bg-secondary'"
              >{{status[getStatus(model.status)]?status[getStatus(model.status)]['value']:'undefined'}}</span
              >
              <!-- <span v-if="model.status == 'approved'" class="badge rounded-pill bg-info"
                >Permintaan Perawatan Diterima</span
              >
              <span
                v-if="model.status == 'realisation'"
                class="badge rounded-pill bg-info"
                >Perawatan Sedang Diproses</span
              >
              <span v-if="model.status == 'reject'" class="badge rounded-pill bg-danger"
                >Permintaan Perawatan Ditolak</span
              >
              <span v-if="model.status == 'done'" class="badge rounded-pill bg-success"
                >Perawatan Selesai</span
              > -->
            </b-col>
            <b-col cols="12" v-if="model.issue">
              <hr>
              <label>Foto Dashboard:</label>
              <br>
              <a
                :href="model.issue.odometer_evidence"
                target="_blank"
              ><img
                :src="model.issue.odometer_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label v-if="model.issue.sim_evidence">Foto SIM:</label>
              <br>
              <a
                :href="model.issue.sim_evidence"
                target="_blank"
              ><img
                :src="model.issue.sim_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label v-if="model.issue.ktp_evidence">Foto KTP:</label>
              <br>
              <a v-if="model.issue.ktp_evidence"
                :href="model.issue.ktp_evidence"
                target="_blank"
              ><img
                :src="model.issue.ktp_evidence"
                width="200"
                alt=""
              ></a>
              <br>
              <br>
              <label v-if="model.issue.stnk_evidence">Foto STNK:</label>
              <br>
              <a v-if="model.issue.stnk_evidence"
                :href="model.issue.stnk_evidence"
                target="_blank"
              ><img
                :src="model.issue.stnk_evidence"
                width="200"
                alt=""
              ></a>
            </b-col>
            <b-col cols="12" class="mt-1">
              <hr />
              <div v-if="loading">
                <b-spinner small />
                Loading...
              </div>
              <div v-else>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.edit')"
                  v-show="model.status != 'request' ? false : true"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="primary"
                  type="button"
                  @click.prevent="editData(model)"
                >
                  Edit
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.delete')"
                  v-show="
                    model.status != 'request' ? false : true
                  "
                  style="margin-right: 5px; margin-top: 5px"
                  variant="danger"
                  type="button"
                  @click.prevent="confirmDelete(model)"
                >
                  Hapus
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.workshop-process')"
                  v-show="model.status_repair == 'plan' && model.status != 'request' && model.status != 'reject' ? true : false"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showProses"
                >
                  Proses Perawatan
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.workshop-finish')"
                  v-show="model.status_repair == 'process' && model.status != 'request' && model.status != 'reject'? true : false"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showDone"
                >
                  Selesai Perawatan
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.approve')"
                  v-show="model.status == 'request' ? true : false"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="success"
                  type="button"
                  @click.prevent="showTerima"
                >
                  Setujui
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.reject')"
                  v-show="model.status == 'request' ? true : false"
                  style="margin-right: 5px; margin-top: 5px"
                  variant="danger"
                  type="button"
                  @click.prevent="showTolak"
                >
                  Tolak
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.finish')"
                  v-show="model.status == 'approved' && !model.bill_type ? true : false"
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="showFinish"
                >
                  Selesai
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.document')"
                  v-show="model.status == 'approved' && model.bill_type == 'user' ? true : false"
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="showModal('modal-upload')"
                >
                  Upload Surat Kesanggupan
                </b-button>
                <b-button
                  v-if="jobTitle.settings.includes('vehicle.maintenance.document')"
                  v-show="model.status == 'approved' && model.bill_type == 'asuransi' ? true : false"
                  style="margin-right: 5px;margin-top: 5px;"
                  variant="info"
                  type="button"
                  @click.prevent="showModal('modal-upload')"
                >
                  Upload BA Kejadian
                </b-button>
              </div>
            </b-col>
          </b-row>
          <b-modal
            id="modal-center"
            ref="my-modal-setuju"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <div class="col-md-12" >
                <div class="row mt-1" v-if="model.vehicle.contact && !loading">
                  <div class="col-md-3">
                    <label>Bengkel</label>
                  </div>
                  <div class="col-md-8">
                    <form-v-select
                      v-model="formApprove.workshop"
                      :dataurl="'vendor/'+model.vehicle.contact.id+'/workshop'"
                      :selected="model.workshop"
                      label=""
                      rules="required"
                      placeholder="Select Tempat Perawatan"
                      @input="changeSelectWorkshop"
                    />
                  </div>
                  <div class="col-md-1" v-if="this.model.vehicle.contact">
                    <b-button
                      class="btn-icon"
                      variant="primary"
                      type="button"
                      @click.prevent="showModalWorkshop"
                    >
                      <span>+</span>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group">
              <div class="col-md-12" >
                <div class="row mt-1">
                  <div class="col-md-3">
                    <label>Tanggal Booking Penanganan</label>
                  </div>
                  <div class="col-md-9">
                    <flat-pickr
                      v-model="formApprove.realisation_date"
                      class="form-control"
                      :config="{ dateFormat: 'Y-m-d' }"
                    />
                  </div>
                </div>
                
              </div>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button type="button" variant="warning" @click.prevent="submitTerima">
                Terima Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-tolak"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin menolak Rencana Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="warning" @click.prevent="submitTolak">
                Tolak Rencana Perawatan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-proses"
            :title="title_modal"
            centered
            hide-footer
          >
            <div class="form-group">
              <p>Apa anda ingin melanjutkan Proses Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="primary mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="warning" @click.prevent="submitProses">
                Proses Perbaikan
              </b-button>
            </div>
          </b-modal>
          <b-modal
            id="modal-center"
            ref="my-modal-done"
            :title="title_modal"
            centered
            hide-footer
          >

            <div class="form-group">
              <p>Apa anda ingin menyelesaikan Perawatan ini ?</p>
            </div>

            <div class="form-group mt-2 float-right">
              <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
              <b-button variant="primary" @click.prevent="submitDone"> Perbaikan Selesai</b-button>
            </div>
          </b-modal>
        </b-card>
      </b-col>
      <b-col cols="6" >
        <b-card>
          <h4 class="mb-2"><feather-icon icon="ListIcon" /> Proses</h4>
          <view-time-line :fields="data_time_line" />
        </b-card>
        <b-card v-if="model.details">
          <h4 class="mb-2"><feather-icon icon="ListIcon" /> Detail Item</h4>
          <b-table
            striped
            responsive
            :items="model.details"
            :fields="table_details"
          >
            
            <template #cell(activity)="data" >
              {{enumActivity.find(x => x.key == data.item.activity_type).name}}
            </template>
            <template #cell(cost)="data" >
              {{data.item.cost|numFormat}}
            </template>
          </b-table>
        </b-card>
        <b-card>
          <b-row cols="12">
            <b-col cols="6">
              <h4 class="mb-2"><feather-icon icon="ListIcon" /> Detail Document</h4>
            </b-col>
            <b-col
              v-if="model.status != 'done'"
              cols="6"
              style="align-self: center; text-align: end;"
            >
              <b-button size="sm" variant="success" @click="uploadDocument"> Tambah</b-button>
              <b-form-file
                id="fileIssueDocument"
                placeholder="Tidak ada file yang dipilih"
                style="display: none"
                @input="selectFileIssueDocument"
              />
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            :items="model.files"
            :fields="table_files"
          >
            <template #cell(_)="data">
              <b-button size="sm" variant="primary btn-icon mr-1" @click="downloadDoc(data.item)">
                <feather-icon icon="DownloadIcon" />
              </b-button>
              <b-button v-if="model.status != 'done'" 
                size="sm" variant="danger btn-icon mr-1" 
                @click="removeDoc(data.item)">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
          </b-table>
        </b-card>
        <b-card v-if="model.issue">
          <b-row cols="12">
            <b-col cols="8">
              <h4 class="mb-2"><feather-icon icon="ListIcon" /> Foto Issue Kendaraan</h4>
            </b-col>
            <b-col
              v-if="model.status != 'done'"
              cols="4"
              style="align-self: center; text-align: end;"
            >
              <b-button size="sm" variant="success" @click="uploadFoto"> Tambah</b-button>
              <b-form-file
                id="fileIssueFoto"
                placeholder="Tidak ada file yang dipilih"
                style="display: none"
                @input="selectFileIssueFoto"
              />
            </b-col>
          </b-row>
          <b-table
            striped
            responsive
            :items="model.issue.photos"
            :fields="table_photo"
          >
            <template #cell(_)="data">
              <b-button v-if="model.status != 'done'" 
                size="sm" variant="danger btn-icon mr-1" 
                @click="removeFoto(data.item, model.issue.id)">
                <feather-icon icon="TrashIcon" />
              </b-button>
            </template>
            <template #cell(photo)="data">
              <a :href="data.item.photo_url" target="_blank"><img :src="data.item.photo_url" :alt="data.item.photo_url" width="100px"></a>
            </template>
          </b-table>
        </b-card>
      </b-col>
      
    </b-row>
    <b-modal
      ref="modal-workshop"
      centered
      title="Tambah Bengkel"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <div><baseform
        :fields="fields"
        :reff-fields="reff_fields"
        :posturl="'workshop'"
        :ismodal="true"
        @onsubmite="submitModal"
      /></div>
    </b-modal>
    <b-modal
      ref="modal-finish"
      centered
      title="Tambah Detail Perawatan"
      size="lg"
      hide-footer
      no-close-on-backdrop
    >
      <div>
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form>
              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label-cols-md="4"
                    label="Tipe Penagihan"
                    label-for="formFinish.bill_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Tipe Penagihan"
                      rules="required"
                    >
                      <b-form-select
                        v-model="formFinish.bill_type"
                        :options="enumBill"
                        value-field="key"
                        text-field="name"
                        placeholder="Pilih Tipe Penagihan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <form-radio
                    v-if="formFinish.bill_type == 'vendor'"
                    v-model="formFinish.reff"
                    label=""
                    rules=""
                    :options="[
                      {name: 'Perawatan Ini', id: false},
                      {name: 'Perawatan Lain', id: true},
                    ]"
                  />
                  <form-v-select
                    v-if="formFinish.reff"
                    ref="maintenance"
                    v-model="formFinish.maintenance"
                    :dataurl="'maintenance'"
                    :selected="formFinish.maintenance"
                    :item_text="'code'"
                    label="Perawatan"
                    rules=""
                    placeholder="Select Perawatan"
                  />
                  <hr>
                  <b-form-group
                    label-cols-md="4"
                    label="Tipe Kegiatan"
                    label-for="formFinish.activity_type"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Activity"
                      rules="required"
                    >
                      <b-form-select
                        v-model="formFinish.activity_type"
                        :options="enumActivity"
                        value-field="key"
                        text-field="name"
                        placeholder="Pilih Tipe Kegiatan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label-cols-md="4"
                    label="Harga Satuan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Cost"
                      rules="required"
                    >
                      <!-- <b-form-input
                        v-model="formFinish.cost"
                        :plaintext="false"
                        placeholder="Inputkan Cost"
                        type="number"
                        :state="errors.length > 0 ? false:null"
                      /> -->
                      <cleave
                        v-model="formFinish.cost"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        placeholder="Harga Satuan"
                        rules="required"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label-cols-md="4"
                    label="QTY"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Qty"
                      rules="required"
                    >
                      <b-form-input
                        v-model="formFinish.qty"
                        type="number"
                        :plaintext="false"
                        placeholder="Inputkan Qty"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                  <b-form-group
                    label-cols-md="4"
                    label="Perawatan"
                  >
                    <form-v-select
                      v-model="formFinish.item"
                      :dataurl="'maintenance-item'"
                      :selected="formFinish.item"
                      label=""
                      rules="required"
                      placeholder="Select Perawatan"
                    />
                  </b-form-group>
                  <b-form-group
                    label-cols-md="4"
                    label="Keterangan"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Keterangan"
                      rules="required"
                    >
                      <b-form-input
                        v-model="formFinish.description"
                        placeholder="Keterangan"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="warning"
                    type="button"
                    @click.prevent="reset"
                  >
                    <span>Reset</span>
                  </b-button>
                  <b-button
                    size="sm"
                    class="mr-1"
                    variant="primary"
                    type="submit"
                    @click.prevent="validationForm"
                  >
                  
                    <span>Tambah</span>
                  </b-button>
                
                </b-col>
                <br>
              </b-row>
            </b-form>
          </validation-observer>
          <br>
          <b-table
            striped
            responsive
            :items="formFinish.details"
            :fields="table_details"
          >
            <template #cell(_)="data">
              <b-button
                class="btn-icon"
                size="sm"
                variant="danger"
                @click.prevent="confirmDelete(data)"
              >
              
                <feather-icon icon="TrashIcon" />
              </b-button>
              <!-- <span
                class="mr-1"
              >
                <b-link @click="confirmDelete(data)">
                  <b-badge variant="danger">
                    <feather-icon icon="TrashIcon" />
                  </b-badge>
                </b-link>
              </span> -->
            </template>
            <template #cell(cost)="data" >
              {{data.item.cost|numFormat}}
            </template>
          </b-table>
          <div class="form-group mt-2 float-right">
            <b-button size="sm" class variant="warning mr-1" @click="hideModal"> Batal </b-button>
            <b-button variant="primary" size="sm" @click.prevent="submitFinish"> Simpan</b-button>
          </div>
        </b-card>
      </div>
    </b-modal>
    <b-modal
        ref="modal-upload"
        :title="model.bill_type == 'user'?'Upload Surat Kesanggupan':'Upload BA Kejadian'"
        centered
        hide-footer
      >
        <b-form-group label-cols-md="4" label="Upload File">
          <b-form-file
            id="extension5"
            v-model="file"
            accept=".pdf, .docx, .doc"
            placeholder="Tidak ada file yang dipilih"
          />
        </b-form-group>

        <div class="form-group mt-2 float-right">
          <b-button class variant="warning mr-1" @click="hideModal"> Batal </b-button>
          <b-button variant="primary" @click.prevent="submitUpload">
            Upload File
          </b-button>
        </div>
      </b-modal>
    <!-- <b-card>
      <b-row>
        <b-col cols="12">
          <b-tabs>
            <b-tab active>
              <template #title>
                <feather-icon icon="TruckIcon" />
                Daftar Perawatan
              </template>
              <b-table
                  striped
                  responsive
                  :items="itemPerawatan"
                  :fields="fieldsPerawatan"
              >
              <template #cell(cost)="data">
                <span>
                  {{ data.value ? 'Rp'+formatPrice(data.value) : '-' }}
                </span>
              </template> 
              <template #cell(realisation_cost)="data">
                <span>
                  {{ data.value ? 'Rp'+formatPrice(data.value) : '-' }}
                </span>
              </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card> -->
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCard,
  BTabs,
  BFormInput,
  BFormCheckbox,
  BTab,
  BTable,
  BFormGroup, 
  BFormFile,
  BFormSelect
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import flatPickr from 'vue-flatpickr-component'
import FormLabel from '@/views/base/form/FormLabel.vue'
import ViewTimeLine from './ViewTimeLine.vue'
import FormVSelect from '@/views/base/form/FormVSelect.vue'
import moment from 'moment'
import baseform from '@/views/base/BaseForm.vue'
import FormRadio from '@/views/base/form/FormRadio.vue'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCard,
    BFormInput,
    flatPickr,
    FormLabel,
    ViewTimeLine,
    BTabs,
    BTab,
    BTable,
    FormVSelect,
    baseform,
    BFormGroup,
    BFormFile,
    BFormSelect,
    FormRadio,
    Cleave
  },
  data() {
    return {
      loading: false,
      model: {
        vehicle: {
          number: '',
        },
        files:[]
      },
      baseroute: 'perawatan',
      posturl: '/maintenance',
      tombol_hide: true,
      ajukan: '',
      renderComp: true,
      title_modal: null,
      tanggalA: null,
      data_ajukan: [],
      data_item: {},
      data_time_line: [],
      dataProses: {
        details: [],
      },
      formProcess: {
        realisation_date: null,
        realisation_cost: null,
        realisation_km: null,
      },
      formApprove: {
        details: [],
        workshop:null
      },
      itemPerawatan : null,
      fieldsPerawatan: [
        { key: 'type', label: 'Jenis Perawatan'},
        { key: 'name', label: 'Nama Perawatan'},
        { key: 'description', label: 'Deskripsi'},
        { key: 'cost', label: 'Biaya' },
        { key: 'realisation_cost', label: 'Biaya Realisasi' },
        { key: 'qty', label: 'Jumlah Item' },
        { key: 'realisation_qty', label: 'Jumlah Realisasi' },
      ],
      jobTitle: {
        settings: [],
      },
      fields:[
        { key:'code', label:'Kode', placeholder:'Kode Bengkel', type: 'input',rules:'required' },
        { key:'name', label:'Nama', placeholder:'Nama Bengkel', type: 'input',rules:'required' },
        { key:'address', label:'Alamat', placeholder:'Alamat',  type: 'autocomplate',rules:'required' },
        { type:'map', label:'', ref:'address'},
        { key:'city', label:'Kota', placeholder:'Masukkan Kota', type: 'autocomplate', indexValue:'name', objectView:'data', dataurl:'/city?search=' },
        { key:'postal_code', label:'Kode pos', placeholder:'Kode Pos',  type: 'input',rules:'' },
        { key:'email', label:'Email', placeholder:'Email',  type: 'input',rules:'required|email' },
        { key:'phone', label:'Telephone', placeholder:'Telephone',  type: 'input',rules:'' },
        { key:'hr'},
        { key:'cp_name', label:'Contact Person', placeholder:'Nama Contact Person', type: 'input',rules:'required' },
        { key:'cp_number', label:'Telephone Contact Person', placeholder:'Telephone', type: 'input',rules:'' },
        { key:'cp_email', label:'Email Contact Person', placeholder:'Email', type: 'input',rules:'' },
        { key:'hr'},
        { key:'register_date', label:'Tanggal Register',  type: 'date',rules:'', val:'now' },
      ],
      reff_fields:[],
      formFinish: {
        details: [],
        reff:false,
        maintenance:null,
        item:null
      },
      formItem:{},
      table_details:[
        { key: 'activity', label: 'Activity'},
        { key: 'cost', label: 'Cost' },
        { key: 'qty', label: 'Qty' },
        { key: 'item.name', label: 'Item'},
        { key: 'description', label: 'Keterangan'},
        '_'
      ],
      table_files:[
        { key: 'name', label: 'File Document'},
        '_'
      ],
      table_photo:[
        { key: 'photo', label: 'File Foto'},
        '_'
      ],
      enumActivity:[
        {key: 'periksa', name: 'Pemeriksaan'},
        {key: 'ganti', name: 'Penggantian'},
        {key: 'tambah', name: 'Penambahan'}
      ],
      enumBill:[
        {key: 'user', name: 'User'},
        {key: 'asuransi', name: 'Asuransi'},
        {key: 'vendor', name: 'Vendor'}
      ],
      file:null,
      status:{
        draft:{color:'info', value:'Rencana Perawatan'},
        request:{color:'info', value:'Butuh Approval Vendor'},
        plan:{color:'info', value:'Belum Ditangani Bengkel'},
        approved:{color:'primary', value:'Dijadwalkan'},
        process:{color:'primary', value:'Penanganan Bengkel'},
        done:{color:'primary', value:'Selesai Penanganan'},
        document:{color:'primary', value:'Kelengkapan Dokumen'},
        finish:{color:'success', value:'Selesai Perawatan'},
        reject:{color:'danger', value:'Perawatan Dibatalkan'}
      },
      options: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: 'thousand',
        },
      }
    }
  },
  watch:{
    $route:function(newid){
      if(newid.params.reload){
        this.getData()
      }
    }
  },
  beforeMount() {
    this.$http.get('/staff?length=').then(ref => {
      this.data_ajukan = ref.data.data
    })
  },
  mounted() {
    this.getData()
  },
  methods: {
    formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
    },
    getData() {
      this.$http.get('auth/me').then( res => {
        for (let setting of res.data.contact.job_title.settings)
        {
          if (setting.approval && setting.module){
            this.jobTitle.settings.push(setting.module.code);
          }
        }
      })

      this.$http.get(this.posturl + '/' + this.$route.params.id).then(res => {
        this.model = res.data
        if(this.model.vehicle.contact)
          this.setForm(this.model.vehicle.contact.id)
          
        this.itemPerawatan = []
        for (var detail of this.model.details){
          this.itemPerawatan.push({
            type: detail.activity_type == 'periksa' ? 'Pemeriksaan' : 
                    detail.activity_type == 'ganti' ? 'Penggantian' : 
                      detail.activity_type == 'tambah' ? 'Penambahan' : '-',
            name: detail.item.name,
            description: detail.item.description,
            cost: detail.cost,
            realisation_cost: detail.realisation_cost,
            qty: detail.qty ?? '-',
            realisation_qty: detail.realisation_qty ?? '-',
          });
        }
        this.data_time_line = res.data.tracks
        this.formApprove.details = []
        this.dataProses.details = []
        for (var index in res.data.details) {
          this.dataProses.details.push({
            id: res.data.details[index].id,
            activity_type: res.data.details[index].activity_type,
            name: res.data.details[index].item.name,
            realisation_qty: res.data.details[index].realisation_qty,
            realisation_cost: res.data.details[index].realisation_cost,
          })
          this.formApprove.details.push({
            id: res.data.details[index].id,
            activity_type: res.data.details[index].activity_type,
            cost: res.data.details[index].cost,
            qty: res.data.details[index].qty,
            item_id: res.data.details[index].item_id,
          })
        }
        this.formApprove.code = res.data.code
        this.formApprove.description = res.data.description
        this.formApprove.plan_date = res.data.plan_date
        this.formApprove.plan_cost = res.data.plan_cost
        this.formApprove.plan_service_cost = res.data.plan_service_cost
        this.formApprove.plan_km = res.data.plan_km
        this.formApprove.is_external = res.data.is_external
        this.formApprove.vehicle_id = res.data.vehicle_id
        this.formApprove.workshop_id = res.data.workshop_id
        this.formApprove.workshop = res.data.workshop
      })
    },
    changeActivity(text) {
      if (text == 'periksa') {
        var value = 'Pemeriksaan'
      } else if (text == 'ganti') {
        var value = 'Penggantian'
      } else {
        var value = 'Penambahan'
      }
      return value
    },
    editData(data) {
      this.$router.push({ name: this.baseroute + '-edit', params: { id: data.id } })
    },
    tanggal(date) {
      return moment(date).local('id').format('DD-MM-YYYY')
    },
    confirmDelete(data) {
      this.$bvModal
        .msgBoxConfirm(
          data.contract_subject != null
            ? 'Anda Ingin Menghapus Data ' +
                data.contract_subject +
                ' ini ? Karena akan berhubungan dengan data lain ?'
            : 'Anda Ingin Menghapus Data ini ? Karena akan berhubungan dengan data lain ?',
          {
            title: 'Please Confirm',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
            okVariant: 'danger',
            okTitle: 'Yes',
            cancelTitle: 'No',
          }
        )
        .then(confirm => {
          if (confirm) {
            this.$http
              .delete(this.posturl + '/' + data.id)
              .then(() => {
                this.$router.push({ name: this.baseroute })
              })
              .catch(err => {
                this.errorSubmit(err)
              })
          }
        })
    },
    showTerima() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-setuju'].show()
    },
    showDone() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-done'].show()
    },
    showTolak() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-tolak'].show()
    },
    showProses() {
      this.title_modal = 'Konfirmasi'
      this.$refs['my-modal-proses'].show()
    },
    showFinish() {
      this.$refs['modal-finish'].show()
    },
    showModal(modal) {
      this.$refs[modal].show()
    },
    submitTerima() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/approve', this.formApprove)
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')

          this.$refs['my-modal-setuju'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Rencana perawatan berhasil diterima',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        }).catch(err => {
          this.errorSubmit(err)
        })
    },
    submitTolak() {
      this.$http.put(this.posturl + '/' + this.$route.params.id + '/reject').then(() => {
        this.getData()
          this.$store.dispatch('sendMessage', '1')
        this.$refs['my-modal-tolak'].hide()
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Rencana perawatan berhasil ditolak',
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      })
    },
    submitProses() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/process')
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-proses'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'perawatan sedang diproses',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitDone() {
      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/done')
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.$refs['my-modal-done'].hide()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Perawatan selesai',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    submitFinish() {
      if(this.formFinish.reff && this.formFinish.maintenance)
        this.formFinish.reff_maintenance = this.formFinish.maintenance.id
        
      if(this.formFinish.details.length == 0)
        return this.$bvToast.toast('Item Detail tidak boleh kosong', {
          title: 'Error',
          solid: true,
          variant: 'danger',
        })


      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/finish', this.formFinish)
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.hideModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Perawatan selesai',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    async submitUpload() {
      if(!this.file){
        return this.$bvToast.toast('File upload tidak boleh kosong', {
          title: 'Error',
          solid: true,
          variant: 'danger',
        })
      }

      let formData = new FormData()
      formData.append('file', this.file)
      let file = await this.$http.post('/upload/file', formData).catch(err => {return this.errorSubmit(err)})
      if(file.data.path != undefined){
        file = file.data.path
      }

      this.$http
        .put(this.posturl + '/' + this.$route.params.id + '/upload', {bill_type:this.model.bill_type, file:file})
        .then(() => {
          this.getData()
          this.$store.dispatch('sendMessage', '1')
          this.hideModal()
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Perawatan selesai',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
        })
    },
    hideModal() {
      this.$refs['my-modal-proses'].hide()
      this.$refs['my-modal-done'].hide()
      this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-tolak'].hide()
      this.$refs['modal-upload'].hide()
      this.$refs['modal-finish'].hide()
    },
    cancel() {
      this.$router.push({ name: this.$route.meta.pageActive })
    },
    errorSubmit(err) {
      const msg = err.response.data.message
      this.$refs['my-modal-proses'].hide()
      // this.$refs['my-modal-setuju'].hide()
      this.$refs['my-modal-done'].hide()
      this.$refs['my-modal-tolak'].hide()
      this.$bvToast.toast(msg ? msg : 'Submit error', {
        title: 'Error',
        solid: true,
        variant: 'danger',
      })
    },
    changeSelectWorkshop(data){
      this.formProcess.workshop_id = data?data.id:null
      this.formApprove.workshop_id = data?data.id:null
    },
    submitModal(){
      let vendor = this.model.vehicle.contact.id
      this.loading = true
      this.$nextTick().then(() => {
        this.loading = false
        this.setForm(vendor.id)
      })
      this.$refs['modal-workshop'].hide()
    },
    setForm(vendor_id){
      this.reff_fields = [
        { key:'vendor_id', value:vendor_id }
      ]
    },
    showModalWorkshop(){
      this.$refs['modal-workshop'].show()
    },
    confirmDelete(data){
      this.formFinish.details.splice(data.index, 1)
    },
    reset(){
      this.formFinish.activity_type = null
      this.formFinish.cost = null
      this.formFinish.qty = null
      this.formFinish.item = null
      this.formFinish.description = null
    },

    validationForm() {
      this.$refs.simpleRules.validate().then( res => {
        if(res){
          this.formFinish.details.push(
            {
              activity_type : this.formFinish.activity_type,
              activity: this.enumActivity.find(x => x.key == this.formFinish.activity_type).name,
              cost: this.formFinish.cost, 
              qty: this.formFinish.qty, 
              item: this.formFinish.item,
              item_id: this.formFinish.item.id,
              description: this.formFinish.description
            })
            this.reset()
            this.$refs.simpleRules.reset()
        }else{
          this.$bvToast.toast('Please correct form input before submit', {
            title: 'Form Error',
            solid: true,
            variant:'danger'
          })
        }
      })
    },
    getStatus(){
      var status = this.model.status
      if(this.model.status_repair == 'done' && status == 'done'){
        status = 'finish'
      }else if(this.model.bill_type && "user,asuransi".includes(this.model.bill_type) && status == 'approved'){
        status = 'document'
      }else if('process,done,plan'.includes(this.model.status_repair) && 'approved,done'.includes(status)){
        status = this.model.status_repair
      }
      return status
    },
    downloadDoc(data){
      if(data.file_url)
        window.open(data.file_url)
      else
        this.$bvToast.toast('File not found', {
          title: 'Error',
          solid: true,
          variant:'danger'
        })
    },
    removeDoc(data){
      this.$http.delete(this.posturl + '/' + this.$route.params.id + '/file/'+data.id).then(res => {
        if(res)
          this.getData()
      })
      .catch(err => {return this.errorSubmit(err)})
    },
    removeFoto(data, id){
      this.$http.delete('issue/' + id + '/photo/'+data.id).then(res => {
        if(res)
          this.getData()
      })
      .catch(err => {return this.errorSubmit(err)})
    },
    uploadDocument() {
      document.getElementById('fileIssueDocument').click();
    },
    uploadFoto() {
      document.getElementById('fileIssueFoto').click();
    },
    async selectFileIssueDocument(data){
      if (data){
        let ext = data.name?data.name.split(".").pop():""
        let arr_file = ["docx", "doc", "pdf", "xls", "xlsx"]
        if(arr_file.includes(ext) && ext == 'pdf'){
          let formData = new FormData()
          formData.append('file', data)

          let resFile = await this.$http.post('/upload/file', formData).catch(err => {return this.errorSubmit(err)})
          if(resFile){
            let params = {
              name : data.name,
              file : resFile.data.path
            }
            this.$http.post(this.posturl + '/' + this.$route.params.id + '/file', params).then(res => {
              if(res)
                this.getData()
            })
            .catch(err => {return this.errorSubmit(err)})
          }
        }
        else
          return this.$bvToast.toast('The file must be a file of type: pdf.', {
            title: 'Error',
            solid: true,
            variant:'danger'
          })
      }
    },
    async selectFileIssueFoto(data){
      if(data){
        let formData = new FormData()
        formData.append('file', data)
        let filename = ""
        let url = 'upload/image'

        let resFile = await this.$http.post(url, formData).catch(err=>{this.errorSubmit(err)})
        if(resFile){
          if(resFile.data.path.split("/"))
            filename = resFile.data.path.split("/").pop()
          
          let params = {
            photo: resFile.data.path
          }

          let url = 'issue/'+this.model.issue.id

          this.$http.post(url+'/photo', params)
          .then(()=>{
            this.getData()
          })
          .catch(err => {return this.errorSubmit(err)})
        }
      }
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
